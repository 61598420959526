<template>
  <div>
    <footer class="footer" style="text-align: left">
      <div
        class="flex items-center hover-bg-light-gray pointer outline-0"
        style="height: 40px;"
        role="button"
        tabindex="0"
        @click="gotoPage('messages')"
        :class="{'concord-blue':activeIndex==11,'gray':activeIndex!=11}"
      >
        <el-popover v-if="uncheckedCount!=0" placement="top-start" trigger="hover" :width="400" style="height: 100%">
          <template #reference>
            <div class="ml4 ellipsis">
              <i aria-hidden="true" class="material-icons md-size2 v-mid mr2" :value="10">
                message
              </i>
              <el-badge :value="uncheckedCount" type="danger" :max="99" :hidden="uncheckedCount == 0">
                <span class="v-mid">
                  我的消息
                </span>
              </el-badge>
            </div>
          </template>
          <div class="my-message-item" v-for = '(item,index) in tableData' :key="index" style="padding-left: 10px;height: 100%" @click="jumpToContract(item)">
            <div class="my-message-item-avatar" style="display: flex;flex-direction: row;justify-content: center;height: 100%;">
              <div style="height: 100%">
                <user-icon :url="isShowUserInfoHeadImg(item.messagesInfoType) ? userInfo.headImgUrl : item.headImgUrl"
                           :name="isShowUserInfoHeadImg(item.messagesInfoType) ? userInfo.userName : item.userName"
                           :uid="isShowUserInfoHeadImg(item.messagesInfoType) ? userInfo.userName : item.userName"
                           :size="42"></user-icon>
              </div>
            </div>
            <div class="my-message-item-text" style="padding-left: 10px;display: flex;height: 100%;margin: 0">
              <span class="meouseHover" >
                <p style="margin:0" v-html="MessageInfoFormat(item).concat(' : ',initSecondInfo(item))"></p>
                <p style="margin-top: 5px">{{transformTimeInfoForList(item.createTime.replace(/-/g,"/"),new Date())}}</p>
              </span>
            </div>
          </div>
        </el-popover>

        <div v-else>
          <div class="ml4 ellipsis">
            <i aria-hidden="true" class="material-icons md-size2 v-mid mr2">
              message
            </i>
            <span class="v-mid" >
                我的消息
              </span>
          </div>
        </div>
      </div>

<!--      <div-->
<!--        class="flex items-center hover-bg-light-gray pointer outline-0"-->
<!--        style="height: 40px"-->
<!--        role="button"-->
<!--        tabindex="0"-->
<!--        @click="showWhatsNew()"-->
<!--      >-->
<!--        <div class="ml4 ellipsis gray">-->
<!--          <i aria-hidden="true" class="material-icons md-size2 v-mid mr2">-->
<!--            notifications-->
<!--          </i>-->
<!--          <span class="v-mid">近期更新</span>-->
<!--        </div>-->
<!--      </div>-->
      <div
        class="flex items-center hover-bg-light-gray pointer outline-0"
        style="height: 40px"
        role="button"
        tabindex="0"
        @click="goToHelpPage"
        :class="{'concord-blue':activeIndex==12,'gray':activeIndex!=12}"
        v-if="!config.noModel"
      >
        <div class="ml4 ellipsis">
          <i aria-hidden="true" class="material-icons md-size2 v-mid mr2">
            help_outline
          </i>
            <span class="v-mid"> 帮助中心</span>
        </div>
      </div>

      <el-dropdown trigger="click" placement="top">
        <div style="width: 100%">
          <div
            class="ml4 footer-tools-container"
            style="
              height: 60px;
              display: flex;
              justify-content: space-between;
              min-width: 0;
              width: 100%;
            "
            @click="getUserTeamInfos"
          >
            <user-icon
                class="footer-team-icon mr2"
                :size="20"
                data-test-current-company-name="META SOTA"
                v-if="currentTeamInfo.id"
                :name="currentTeamInfo.teamName"
                :uid="currentTeamInfo.id"
            ></user-icon>
<!--            <i-->
<!--              data-test-current-company-name="META SOTA"-->
<!--              aria-hidden="true"-->
<!--              class="material-icons md-size2 v-mid mr2"-->
<!--            >-->
<!--              settings-->
<!--            </i>-->
            <span class="footer-team-name gray truncate" v-if="currentTeamInfo.teamName" :title="currentTeamInfo.teamName" style="font-size: 1rem; flex: 1;min-width: 0">{{currentTeamInfo.teamName}} </span>
            <i class="material-icons md-size2 v-mid mr3"> unfold_more </i>
          </div>
        </div>

        <template #dropdown>
          <!--          <el-dropdown-menu>-->
          <!--            <el-dropdown-item class="flex items-center">-->
          <!--              <i class="material-icons md-size2 concord-orange v-mid">-->
          <!--                auto_awesome-->
          <!--              </i>-->
          <!--              <span class="ml1 v-mid">-->
          <!--                            升级-->
          <!--                          </span>-->
          <!--            </el-dropdown-item>-->
          <!--            <el-dropdown-item class="flex items-center" v-if="userInfo && userInfo.mainSiteTeamId"-->
          <!--                              @click="inviteUser()">-->
          <!--              <i class="material-icons md-size2 v-mid">-->
          <!--                person_add-->
          <!--              </i>-->
          <!--              <span class="ml1 v-mid">-->
          <!--                            邀请成员-->
          <!--                          </span>-->
          <!--            </el-dropdown-item>-->
          <!--            <el-dropdown-item class="flex items-center" @click="openNewPage('publishedTemplateCategory')">-->
          <!--              <i class="icon iconfont metago-icon category-icon material-icons footer-icon"></i>-->
          <!--              <span class="ml1 v-mid">-->
          <!--                            模板类别-->
          <!--                          </span>-->
          <!--            </el-dropdown-item>-->
          <!--            <el-dropdown-item class="flex items-center" @click="openNewPage('organization')">-->
          <!--              <i class="icon iconfont metago-icon organization-icon material-icons footer-icon"></i>-->
          <!--              <span class="ml1 v-mid">-->
          <!--                            组织结构-->
          <!--                          </span>-->
          <!--            </el-dropdown-item>-->
          <!--            <el-dropdown-item class="flex items-center" @click="openNewPage('dataDictionary')">-->
          <!--              <i class="icon iconfont metago-icon dictionary-icon material-icons footer-icon"></i>-->
          <!--              <span class="ml1 v-mid">-->
          <!--                            数据词典-->
          <!--                          </span>-->
          <!--            </el-dropdown-item>-->
          <!--          </el-dropdown-menu>-->
          <el-dropdown-menu style="width: 218px">
            <el-dropdown-item
              @mouseenter="showTeams = true"
              @mouseleave="showTeams = false"
              >
              <i aria-hidden="true" class="material-icons v-mid md-size2"><svg-icon name="group" width="19" height="19"></svg-icon></i>
              <span>团队列表</span>
              <el-badge v-if="allTeamMessageCount - uncheckedCount > 0" :is-dot="true" style="margin-left: 2px"></el-badge>
            </el-dropdown-item>
            <el-dropdown-item
              >
              <el-tooltip content="开发中" placement="right">
                <span>
                  <i aria-hidden="true" class="material-icons v-mid md-size2"><svg-icon name="upgrade" width="19" height="19"></svg-icon></i>
                  <span data-tag-id="left-side-upgrade">升级</span>
                </span>

              </el-tooltip>
            </el-dropdown-item
            >
            <el-dropdown-item @click="inviteUser" v-if="!config.noModel"
              ><i aria-hidden="true" class="material-icons v-mid md-size2">
                person_add </i
              >邀请</el-dropdown-item
            >
            <el-dropdown-item @click="gotoSetting"
              ><i className="material-icons v-mid md-size2">settings</i
              >设置</el-dropdown-item
            >
            <el-dropdown-item @click="signOut"
              ><i className="material-icons v-mid md-size2">close</i
              >退出登录</el-dropdown-item
            >
          </el-dropdown-menu>
        </template>
        <el-dropdown-menu
            class="custom-scroller"
          style="
            width: 218px;
            position: absolute;
            left: 225px;
            top: -263px;
            border: #e4e7ed solid 1px;
            height: 200px;
            overflow: auto;
          "
          v-show="showTeams && teamInfos.length !== 0"
          @mouseenter="showTeams = true"
          @mouseleave="showTeams = false"
        >
          <el-dropdown-item
            v-for="teamInfo in teamInfos"
            :key="teamInfo.team_id"
            @click="switchUserCurrentTeam(teamInfo)"
            style="justify-content: space-between"
          >
<!--            <div :style="{visibility:teamInfo.is_active ? 'visible':'hidden'}" class="currentTeam"></div>-->
            <span class="truncate">{{ teamInfo.team_name }}</span>
            <div v-if="teamInfo.is_active" class="group-item-badge">✔</div>
            <div v-else-if="teamInfo.unCheckMessagesCount > 0" class="group-item-badge unChecked_badge">{{teamInfo.unCheckMessagesCount > 99 ? '99+' : teamInfo.unCheckMessagesCount}}</div>
            </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </footer>

    <el-dialog
      title="邀请成员"
      v-model="organizationInviteOuterUserDialogVisible"
      @opened="organizationInviteOuterUserDialogOpened"
      center
    >
      <organizationInviteUser
        ref="organization-invite-user"
        :organization_name="rootOrganization.name"
        :organization_id="rootOrganization.id"
      >
      </organizationInviteUser>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="organizationInviteOuterUserDialogVisible = false"
            >关 闭</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
<!--  <el-drawer-->
<!--    v-model="needHelp"-->
<!--    title="帮助"-->
<!--    direction="rtl"-->
<!--    close-on-click-modal-->
<!--  >-->
<!--    <div class="help-content">-->
<!--      <div style="text-align: left; font-size: 16px; font-weight: bold">-->
<!--        <span>请描述您遇到的问题：</span>-->
<!--      </div>-->
<!--      <div style="display: flex; margin-top: 10px">-->
<!--        <el-input>-->
<!--          <template #prefix>-->
<!--            <i class="el-icon-search"></i>-->
<!--          </template>-->
<!--        </el-input>-->
<!--        <el-button>搜索</el-button>-->
<!--      </div>-->
<!--      <div-->
<!--        style="-->
<!--          text-align: left;-->
<!--          font-size: 16px;-->
<!--          font-weight: bold;-->
<!--          margin-top: 20px;-->
<!--        "-->
<!--      >-->
<!--        <span style="display: block">常见问题：</span>-->
<!--        <div style="margin-top: 10px">-->
<!--          <span style="font-size: 14px"-->
<!--            >1.怎么辨别哪些文件是需要我处理的？</span-->
<!--          >-->
<!--          <div-->
<!--            style="-->
<!--              display: flex;-->
<!--              align-items: center;-->
<!--              background: #f2f2f2;-->
<!--              border-radius: 10px;-->
<!--              width: 100%;-->
<!--              margin-top: 10px;-->
<!--              height: 40px;-->
<!--              padding-left: 10px;-->
<!--            "-->
<!--          >-->
<!--            <el-image :src="correct" style="height: 20px"></el-image>-->
<!--            <span style="font-size: 12px"-->
<!--              >&nbsp;状态中有黄色三角感叹图形表明这是当前用户需要处理的文件</span-->
<!--            >-->
<!--          </div>-->
<!--          <br />-->
<!--          <span style="font-size: 14px">2.怎么搜索我想要查找的内容？</span>-->
<!--          <div-->
<!--            style="-->
<!--              display: flex;-->
<!--              align-items: center;-->
<!--              background: #f2f2f2;-->
<!--              border-radius: 10px;-->
<!--              width: 100%;-->
<!--              margin-top: 10px;-->
<!--              height: 40px;-->
<!--              padding-left: 10px;-->
<!--            "-->
<!--          >-->
<!--            <el-image :src="correct" style="height: 20px"></el-image>-->
<!--            <span style="font-size: 12px"-->
<!--              >&nbsp;点击过滤，里面可以搜索或分类</span-->
<!--            >-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </el-drawer>-->
<!--  <div class="whats-new whats-new-hide" ref="whatsNew" @click="hideWhatsNew">-->
<!--    <div style="float: right; cursor: pointer" @click="hideWhatsNew">-->
<!--      <i class="el-icon-close"></i>-->
<!--    </div>-->
<!--    <h2 style="color: #0a84ff">近期更新</h2>-->
<!--    <a href="#">文档diff，更清晰的查看不同版本文档之间的差异</a>-->
<!--    <a href="#">辅助审查，让您清晰了解合同中存在的问题</a>-->
<!--  </div>-->
  <invite-user ref="invite" :teamId="userInfo.mainSiteTeamId"></invite-user>
</template>

<script>
import {
  getAllTeamInfos,
  switchCurrentTeam,
  getUnCheckMessagesCount,
  getLatestMessages,
  updateMessagesStatusToChecked,
  clmLogOut,
  getAllTeamUnCheckMessagesCount,
  getMessages,
} from "../api/api";
import organizationInviteUser from "./OrganizationInviteUser";
import { ElMessage } from "element-plus";
import correct from "@/assets/images/correct.png";
import InviteUser from "./InviteUser";
import UserIcon from "../components/UserIcon";
import {colorLog, delCookie, deleteCookie, getCookies, MetaMessage, singleListenerManager,transformTimeInfoForList} from "../util";
import {globalTagClick} from "../assets/js/tag/tag";
import {MessagesInfoType} from "../constant/messages/MessagesInfoType";
import config from "../config";

export default {
  name: "leftMenuFooter",
  props: ["activeFooter"],
  components: { InviteUser, organizationInviteUser, UserIcon},
  data: function () {
    return {
      config:config,
      MessagesInfoType,
      organizationInviteOuterUserDialogVisible: false,
      userInfo: JSON.parse(sessionStorage.getItem("userInfo")),
      rootOrganization: null,
      // needHelp: false,
      metaMessage: new MetaMessage(),
      showWhatsNewDiv: false,
      correct,
      showTeams: false,
      teamInfos: [],
      currentTeamInfo: {},
      activeIndex: this.activeFooter,
      uncheckedCount: 0,
      tableData: [],
      pageSize: 5,
      currentIndex: 1,
      allTeamMessageCount: 0,
    };
  },
  created() {
    singleListenerManager.on("sessionStorage",msg => {
      this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      console.log(msg);
    })
  },
  mounted(){
    //因为main.js获取teamInfo是在created之后执行的，只有在这个钩子才能拿到获取的teamInfo
    this.currentTeamInfo = sessionStorage.getItem('teamInfo');
    if(typeof this.currentTeamInfo == 'string') this.currentTeamInfo = JSON.parse(this.currentTeamInfo);
  },
  methods: {
    transformTimeInfoForList,
    signOut(){
      globalTagClick('left-side-sign-out');
      clmLogOut().then(res=>{
          console.log(res)
          if(res.data.code === 0){
            this.clearAllCookies();
            this.$router.push({
              name:'home'
            })
          }
        }).catch(()=>{
          ElMessage.warning('退出登录失败，请重试。');
        })
    },
    clearAllCookies() {
      let domains = ['.'+document.domain];
      if(document.domain.split('.').length > 2){
        //域名有两个及以上的点 如'clm.metago.cn' 'pop.clm.metalaw.com'
        //push的字符串都是如'.metago.cn' '.metalaw.com'
        domains.push('.' + document.domain.split('.').slice(-2).join('.'))
      }

      let cookieNames = ['uid','sid','userName','phone','email','headImgUrl','mainSiteTeamId'];
      domains.forEach(domain=>{
        for(let name of cookieNames){
          deleteCookie(name,domain);
        }
      })
    },
    goToHelpPage(){
      globalTagClick('left-side-help-center');
      this.$router.push({name:'helpCenter'});
    },
    openNewPage: function (name) {
      let newPage = this.$router.resolve({
        name: name,
      });
      window.open(newPage.href);
    },
    inviteUser: function () {
      globalTagClick('left-side-invite-user');
      this.$refs.invite.inviteUser();
    },
    organizationInviteOuterUserDialogOpened: function () {
      this.$refs["organization-invite-user"].initQRCode();
    },
    showWhatsNew() {
      this.$refs.whatsNew.className = "whats-new whats-new-show";
    },
    hideWhatsNew() {
      this.$refs.whatsNew.className = "whats-new whats-new-hide";
    },
    gotoSetting() {
      globalTagClick('left-side-setting');
      this.$router.push("/settings/profile");
    },
    switchUserCurrentTeam(teamInfo) {
      globalTagClick('left-side-switch-current-team',{teamInfo});
      if (teamInfo.is_active) {
      } else {
        switchCurrentTeam(teamInfo.team_id).then((res) => {
          this.currentTeamInfo = teamInfo;
          if (res.data.code == 0) {
            ElMessage.success("切换成功");
            getAllTeamInfos().then((res) => {
              if (res.data.code == 0) {
                this.teamInfos = res.data.data;
                location.replace(location.href.split('?')[0]);
                location.reload();
                // this.$router.push({
                //   name:this.$router.currentRoute.value
                // })
              }
            });
            }
          });
        }

      this.showTeams = false;
    },
    getUserTeamInfos() {
      getAllTeamInfos().then((res) => {
        this.teamInfos = res.data.data;
        this.allTeamMessageCount = 0;
        getAllTeamUnCheckMessagesCount().then(re => {
          let teamUncheckCount = re.data.data;
          for (const i in this.teamInfos) {
            for (const j in teamUncheckCount) {
                if (this.teamInfos[i].team_id === teamUncheckCount[j].team_id){
                  this.allTeamMessageCount += teamUncheckCount[j].unCheckMessagesCount;
                  Object.assign(this.teamInfos[i],teamUncheckCount[j]);
                  break;
                }
            }
          }
        })
      });
    },
    gotoPage:function (name){
      globalTagClick('left-side-my-info');
      this.$router.push({name:name})
    },
    changeActiveIndex:function (val) {
      this.activeIndex = val
    },
    initMessagesCount:function() {
      getUnCheckMessagesCount().then(res => {
        this.uncheckedCount = res.data.data;
      }).catch(err => {
        console.error('getContractUnCheckMessagesCount   err ==== ', err);
        this.metaMessage['error']('查询未读消息数失败');
      });
    },
    initTableData : function () {
      getMessages({columnName : "isCheck", pageIndex : this.currentIndex , pageSize : this.pageSize , isCheck : false}).then(res => {
        if (res.data.code === 0) {
          this.tableData = res.data.data.content;
        } else {
          this.metaMessage['error']('缩略数据查询失败');
        }
      }).catch(err => {
        console.error('getDataByOnlyUncheckTop5  err ==== ', err);
        this.metaMessage['error']('缩略数据查询失败');
      });
    },
    jumpToContract : function (item) {
      globalTagClick('left-menu-footer-jump',item);
      updateMessagesStatusToChecked(item.id).then(res => {
        if (res.status == 200) {
          this.pushEmit();
          if (item.url != null){
            window.open(item.url);
          }else{
            if (item.sourceType == 2){
              if (item.messagesInfoType === MessagesInfoType.ADD_COMMENT
                      || item.messagesInfoType === MessagesInfoType.ADD_SUB_COMMENT
                      || item.messagesInfoType === MessagesInfoType.ADD_COMMENT_WITHIN_APPROVAL){
                this.$router.push({name: 'contractView', query: {id: item.documentId,toolActiveName : "comments",markId : item.markId}});
              }else {
                this.$router.push({name: 'contractView', query: {id: item.documentId}});
              }
            }else if(item.sourceType == 1){
              this.$router.push({name:'templatePublish',query:{templateId:item.documentId}});
            }else{
              //不跳转
            }
          }
        }
      }).catch(e => {
        console.log("update MessageStatus Error => ",e)
        this.metaMessage['error']('网络错误');
      });
    },
    pushEmit : function () {
      this.$parent.refreshCount = true
    },
    MessageInfoFormat:function(row){
      let messagesInfoType = row.messagesInfoType;
      switch (messagesInfoType) {
              //后端枚举 -> 前端枚举
        case MessagesInfoType.AWAIT_APPROVAL:
          return "您有一份合同待审批";
        case MessagesInfoType.AWAIT_PUBLISHED_TEMPLATE_APPROVAL:
        case MessagesInfoType.REPUBLISH_PUBLISHED_TEMPLATE:
          return "您有一份模板待审批";
        case MessagesInfoType.ACCEPT_PUBLISHED_TEMPLATE_APPROVAL:
          return  row.userName+" 通过了您发布的模板";
        case MessagesInfoType.REJECT_PUBLISHED_TEMPLATE_APPROVAL:
          return  row.userName+" 驳回了您发布的模板";
        case MessagesInfoType.SET_READY_TO_SIGN:
          return  row.userName+" 将合同设为定稿";
        case MessagesInfoType.CONTRACT_SIGNED:
          return  row.userName+" 将合同设为已签署";
        case MessagesInfoType.ACCEPT_APPROVAL:
          if (row.documentCreatorId === this.userInfo.uid){
            return "您发起的合同审批通过";
          }else{
            return "合同审批通过";
          }
        case MessagesInfoType.REJECT_APPROVAL:
          if (row.documentCreatorId === this.userInfo.uid){
            return  row.userName+" 驳回了您发起的合同"
          }else{
            return  row.userName+" 驳回了合同"
          }
        case MessagesInfoType.ADD_COLLABORATOR:
          return  row.userName+" 添加你为合同的协作者";
        case MessagesInfoType.JOIN_TEAM:
          return  row.userName+" 成员加入了团队";
        case MessagesInfoType.JOIN_TEAM_FAIL:
          return "加入团队失败";
        case MessagesInfoType.DIFF_DIFFERENT:
          return row.userName+" 上传了已签署版合同,与定稿版存在<span style='color: red'>"+row.diffCount+"</span>处不同";
        case MessagesInfoType.ADD_COMMENT:
          return row.userName+"评论了您发布的合同";
        case MessagesInfoType.ADD_SUB_COMMENT:
          return row.userName+"回复了您的评论";
        case MessagesInfoType.ADD_COMMENT_WITHIN_APPROVAL:
          return "待您审批的合同新增了一条评论";
        case MessagesInfoType.CONTRACT_REVERT:
          return '合同已被退回到"草稿"状态';
        case MessagesInfoType.ACCEPT_STAGE_APPROVAL:
          return "<span style='font-weight: bold'>" + row.userName + "</span>&nbsp在审批步骤" +row.sortIndex+ "中通过了审批";
        case MessagesInfoType.CANCEL_CONTRACT:
          return "<span style='font-weight: bold'>" + row.userName + "</span>&nbsp取消了合同";
        case MessagesInfoType.UPLOAD_INVOICE:
          return "<span style='font-weight: bold'>" + row.userName + "</span>&nbsp为合同上传了发票";
        case MessagesInfoType.CONTRACT_BULLETIN_CREATED:
          return "<span style='font-weight: bold'>" + row.userName + "</span>&nbsp为合同创建了公告";
        case MessagesInfoType.CONTRACT_BULLETIN_UPDATED:
          return "<span style='font-weight: bold'>" + row.userName + "</span>&nbsp修改了合同的公告";
        case MessagesInfoType.CONTRACT_BULLETIN_DELETED:
          return "<span style='font-weight: bold'>" + row.userName + "</span>&nbsp删除了合同的公告";
        case MessagesInfoType.CONTRACT_TAG_COMPLETED:
          return "<span style='font-weight: bold'>" + row.userName + "</span>&nbsp完成了合同的Tag待办事项";
        case MessagesInfoType.CONTRACT_TAG_COMPLETE_CANCELED:
          return "<span style='font-weight: bold'>" + row.userName + "</span>&nbsp取消了合同的Tag待办事项";
        default :
          return "数据陈旧,无法正确显示数据";
      }
    },
    isShowUserInfoHeadImg:function(messagesInfoType){
      return messagesInfoType === MessagesInfoType.AWAIT_APPROVAL
              || messagesInfoType === MessagesInfoType.AWAIT_PUBLISHED_TEMPLATE_APPROVAL
              || messagesInfoType === MessagesInfoType.JOIN_TEAM_FAIL;
    },
    initSecondInfo : function(row){
      let messagesInfoType = row.messagesInfoType;
      return MessagesInfoType.JOIN_TEAM == messagesInfoType || MessagesInfoType.JOIN_TEAM_FAIL == messagesInfoType ? row.teamName : row.documentName;
    },
  },
  unmounted() {},
};
</script>

<style scoped>
.footer {
  font-size: 14px;
}
.material-icons[class] {
  display:initial;
}
.footer i {
  font-size: 18px;
}
.my-message-item{
  display: flex;
  cursor: pointer;
}
.currentTeam {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--lightBlue);
  flex:none;
}
.el-dropdown-menu__item{
  display: flex;
  align-items: center;
}
.concord-blue, .hover-concord-blue:focus, .hover-concord-blue:hover {
  color: #5280FF
}
.mid-gray {
  color: #555;
}
.meouseHover {
    font-size: 12px;
}
.meouseHover:hover {
  color: #5280FF;
}

.group-item-badge{
  font-size:.875rem;
  min-width: 1.3em;
  height: 1.3em;
  padding: .2em;
  box-sizing: content-box;
  display: block;
  white-space: nowrap;
  background: var(--lightBlue);
  color:white;
  border-radius: 99px;
  line-height: initial;
  text-align: center;
}

.group-item-badge.unChecked_badge{
  background: var(--el-color-error) ;
  position: relative;
}

/deep/ .el-badge__content.is-fixed{
  right: 0;
}
/deep/ .el-badge__content{
  border: 0px;
}
/deep/ .el-badge{
  background-color: rgba(0,0,0,0);
}
</style>
