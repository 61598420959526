<template>
  <el-dialog
      title="邀请成员"
      v-model="organizationInviteOuterUserDialogVisible"
      @opened="organizationInviteOuterUserDialogOpened"
      width="800px"
      center>
    <div class="invite-dialog" @click="onDialogClick" v-loading="sendMailLoading">
      <div class="flex">
    <el-tabs v-model="currentTab">
      <el-tab-pane name="QRCode" label="通过链接分享">
        <organization-invite-user
            ref="organization-invite-user"
            :organizations="currentOrganization">
        </organization-invite-user>

      </el-tab-pane>
      <el-tab-pane style="
                height: 100%;
                position: relative;
          " name="Email" label="通过邮件分享">
        <div id="receivers">
          <div style="position: relative;display: flex;align-items: flex-start;">
            <div class="tags" style="padding: 0 10px 0 10px;width:100%;margin-left: 0"
            :style="{'padding-top':receivers.length > 0? '10px' : 0}"
            >
              <el-tag closable v-for="(item,index) in receivers" :key="index" class="contract-tag"
                      style="margin: 0px 10px 10px 0"
                      @close="removeReceiver(index)">{{ item.uid ? item.uid : item.email }}
              </el-tag>
              <el-input style="flex:1;height: auto;line-height: 1;"
                        :input-style="{
                'border-radius':0,'border':'0','background':'#F4F4F4','padding':'0','height':'30px',
                'min-width':'50px',

              }"
                         @keypress="inputkeyPress" placeholder="请输入邮箱" v-model="inputMail"
              >

              </el-input>
            </div>

          </div>
        </div>
        <div>
          <el-table height="209px" :data="inviteUsers" :show-header="false" :row-style="{cursor:'pointer'}" empty-text=" ">
            <el-table-column width="48">
              <template #default="scope">

                <user-icon
                    :url="null"
                    :uid="scope.row.email"
                    :name="scope.row.email != null ? scope.row.email.charAt(0) : scope.row.uid.charAt(0)"
                    :sub-icon="scope.row.status==0 ? 'email': ''"
                >
                </user-icon>
<!--                <div class="head-icon">-->
<!--                  <img v-if="scope.row.headImgUrl" :src="scope.row.headImgUrl"/>-->
<!--                  <span v-else>-->
<!--                      {{ scope.row.email != null ? scope.row.email.charAt(0) : scope.row.uid.charAt(0) }}-->
<!--                    </span>-->
<!--                  <div class="email-badge" v-if="scope.row.status==0">-->
<!--                    <i class="material-icons" style="font-size: 14px;">mail</i>-->
<!--                  </div>-->
<!--                </div>-->
              </template>
            </el-table-column>
            <el-table-column class-name="column-email" min-width="200">
              <template #default="scope">
                <div>{{ scope.row.uid }}</div>
                <div>{{ scope.row.email }}</div>
              </template>
            </el-table-column>
            <el-table-column width="100" prop="operation">
              <template #default="scope">
                <el-button size="small" v-if="scope.row.status==0" @click="resend(scope.row)">
                  重新发送
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="invite-operate-panel">
          <div style="display: flex;align-items:center;">
            <el-image :src="correct" style="height: 20px"></el-image>
            <span>您可以同时邀请多个用户</span></div>

          <el-button type="default" class="email-invite-btn" @click="sendInviteMails">邀请</el-button>
        </div>
      </el-tab-pane>
    </el-tabs>
        <div class="invite-dialog-rightPart" style="margin-left:20px;margin-top: 40px">
          <div class="idr-img"></div>
<!--          <div style="font-size: 16px;font-weight: bold">-->
<!--            <span>您可以通过微信小程序或链接方式将文档分享给朋友或自己，方便手机使用</span>-->
<!--          </div>-->
          <div class="holder"></div>
          <p>选择部门</p>
          <div>

            <el-select v-model="currentOrganization" style="margin-bottom: 9px;" value-key="name" multiple @change="selectChanged">
              <el-option v-for="(item,index) in organizationList" :key="index" :label="item.name" :value="item" ></el-option>
            </el-select>
          </div>
        </div>
      </div>
    </div>

  </el-dialog>
</template>

<script>
import {getInviteList, getTeamUser, getTeamOrganizations, inviteUser} from "../api/api";
import {ElMessage} from "element-plus";
import OrganizationInviteUser from "./OrganizationInviteUser";
// import "../assets/css/contract.css"
import iniviteImg from "../assets/images/invite_user.png"
import config from "../config";
import {MetaMessage} from "../util";
import correct from "@/assets/images/correct.png";
import UserIcon from "./UserIcon";
import {globalTagClick} from "../assets/js/tag/tag";
export default {
  name: "InviteUser",
  components: {UserIcon, OrganizationInviteUser},
  props:['teamId','activeOrganization'],
  data(){
    return{
      organizationInviteOuterUserDialogVisible:false,
      rootOrganization: null,
      currentTab:'QRCode',
      showAutoComplete:false,
      teamMembers:[],
      receivers:[],
      inputMail:'',
      sharedList:[],
      logo:iniviteImg,
      organizationList:[],
      currentOrganization:[],
      inviteUsers:[],
      metaMessage: new MetaMessage(),
      sendMailLoading:false,
      correct,
      userInfo:JSON.parse(sessionStorage.getItem("userInfo"))
    }
  },
  mounted() {
    if(this.teamId!=null){
      this.loadTeamMember();
    }

  },
  watch:{
    'teamId':function (){
      if(this.teamId!=null){
        this.loadTeamMember();
      }
    },
    'organizationInviteOuterUserDialogVisible':{
      handler(){
        this.$nextTick(()=>{
          document.querySelector('.invite-dialog-rightPart .el-select__tags > span')?.classList.add('custom-scroller');
        })
      }
    }
    // ,
    // 'activeOrganization':function (){
    //   if(this.activeOrganization!=null){
    //     this.currentOrganization.push(this.activeOrganization)
    //   }
    // }
  },
  methods:{
    selectChanged(){
      if(this.currentOrganization.length==0){
        this.currentOrganization.push(this.rootOrganization)
      }
    },
    loadTeamMember(){
      getTeamUser(this.teamId).then(res=>{
        if (res.data.code === 0){
          this.teamMembers=res.data.data;
          this.loadInviteUsers();
        }else {
          ElMessage.error("加载团队成员失败")
        }
      })
    },
    loadInviteUsers(){
      getInviteList().then(res=>{
        this.inviteUsers=res.data.data;
      }).catch(err=>{
        console.error("loadInviteUsers err==="+err);
        ElMessage.error("加载邀请列表失败")
      })
    },
    organizationInviteOuterUserDialogOpened: function () {
      this.$refs["organization-invite-user"].initQRCode();
    },
    inviteUser: function () {
      console.log('inviteUser  this.userInfo === ', this.userInfo);
      getTeamOrganizations(this.teamId).then(res => {
        if (res.data.code === 0) {
          this.rootOrganization = null;
          this.currentOrganization=[]
          //如是从Organization界面点击的邀请成员,则会传入一个activeOrganization,回写到"选择部门"中
          if (this.activeOrganization == null){
            for (const o of res.data.data) {
              if (!o.parentId) {
                this.rootOrganization = o;
                this.currentOrganization.push(this.rootOrganization)
                break;
              }
            }
            if(this.userInfo.isManagerRole){
              this.organizationList=res.data.data.filter((item)=>item.name!=='管理员部门');
            }
            else{
              this.organizationList=[this.rootOrganization];
            }
          }else{
            this.rootOrganization= {id:this.activeOrganization.id , name:this.activeOrganization.label};
            this.currentOrganization.push(this.rootOrganization);
            this.organizationList=this.rootOrganization;
          }
          if(this.userInfo.isManagerRole){
            this.organizationList=res.data.data.filter((item)=>item.name!=='管理员部门');
          }
          else{
            this.organizationList=[this.rootOrganization]
          }

          if (this.rootOrganization != null) {
            this.organizationInviteOuterUserDialogVisible = true;
          } else {
            ElMessage.error("未找到根节点组织结构");
          }
        } else {
          ElMessage.error("获取根节点组织结构失败");
        }
      }).catch(err => {
        console.error('inviteUser  getTeamOrganizations  err:', err);
        ElMessage.error("获取根节点组织结构失败");
      })
    },
    onRowClick(item) {
      this.inputMail = ''
      this.showAutoComplete=false;
      this.addReceiver(item)

    },
    addReceiver(receiver) {

      if (receiver.email == '' && (receiver.uid == null || receiver.uid == '')) return;
      receiver.status = 0;
      console.log(receiver);
      this.receivers.push(receiver);
      this.inputMail = '';

    },
    FilterFuc(item) {
      return ((item.uid && item.uid.indexOf(this.inputMail) > -1) || (item.email && item.email.indexOf(this.inputMail) > 0)) && this.receivers.find((a) => a.uid === item.uid && a.email === item.email) == null && this.sharedList.find((b) => b.uid === item.uid && b.email == item.email) == null
    },
    addByMail() {

      this.showAutoComplete=false;
      if (this.inputMail != '') {
        let pattern = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if(pattern.test(this.inputMail)) {
          this.addReceiver({email: this.inputMail})
        }
        else{
          ElMessage.warning("邮箱格式不正确");
        }
      }

    },
    removeReceiver(index) {
      this.receivers.splice(index, 1)
    },
    inputkeyPress(e) {
      if (e.key == 'Enter') {
        if (/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(this.inputMail)) {
          this.addReceiver({email: this.inputMail})
        }else{
          ElMessage.warning("邮箱格式不正确");
        }
      }
    },
    sendInviteMails(){
      globalTagClick('invite-email-send');
      this.sendMailLoading=true;
      this.addByMail();
      let ids = [];
      this.currentOrganization.forEach((item)=>{ids.push(item.id)})
      let data={};
      data.receivers=this.receivers;
      data.url=config.baseUrl+'clm/#/index';
      data.msg="邀请加入组织";
      data.organizationIds = ids;

      inviteUser(this.teamId,data).then(res=>{
        if(res.data.code==0){
          this.sendMailLoading=false;
          this.receivers=[];
          this.inviteUsers = this.inviteUsers.concat(res.data.data)
          console.log(this.inviteUsers)
        }
      }).catch(err=>{
        console.error("inviteUser err====>"+err)
      });
    },
    onDialogClick(e){
      let receverDiv = document.getElementById("receivers")
      if(!receverDiv.contains(e.target)){
        this.addByMail();
      }
    },
    resend(item){
      globalTagClick('invite-email-resend-invite');

      this.sendMailLoading=true;
      let ids = [];
      this.currentOrganization.forEach((item)=>{ids.push(item.id)})
      let data={};
      data.receivers=[item]
      data.url=config.baseUrl+'clm/#/index';
      data.msg="邀请加入组织";
      data.organizationIds = ids;

      inviteUser(this.teamId,data).then(res=>{
        if(res.data.code==0){
          this.metaMessage.success("发送成功")
          this.sendMailLoading=false;
        }
      }).catch(err=>{
        console.error("inviteUser err====>"+err)
      });
    }
  }
}
</script>

<style scoped>
p{
  margin: 0;
}
.holder{
  flex-basis: 0%;
  flex-grow: 1;
}
.invite-dialog-rightPart{
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.approver >>> .el-table::before {
  height: 0px;
}

.approver >>> .el-table td {
  border: none;
}

.approver >>> .el-table .cell {
  overflow: unset;
}

.head-icon {
  position: relative;
}

.head-icon img, .head-icon span {
  width: var(--userIconSize);
  height: var(--userIconSize);
  line-height: var(--userIconSize);

  display: block;
  border-radius: 18px;
  text-align: center;
  padding: 0;
  color: white;
  font-weight: bold;
  font-size: 16px;
  border: solid 1px white;
  box-sizing: border-box;
}

.head-icon span {
  background: #2190e7;
}

.view-type-dropdown >>>   .el-button{

  border: 0;
  border-radius: 0;
  height: 53px;
  background: #F4F4F4;
  border-bottom: solid 1px #999;
}

.view-type-dropdown >>> {
  margin-left: 10px;
}

.invite-dialog >>> .el-tabs__content{
  overflow: visible;
  height: 320px;
  width: 500px;
}
.invite-dialog >>> #pane-QRCode{
  height: 100%;

}

.invite-dialog >>> .inviteUrlWrapper{
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.invite-dialog >>> .el-table::before {
  height: 0px;
}

.invite-dialog >>> .el-table td {
  border: none;
}

.invite-dialog >>> .el-table .cell {
  overflow: unset;
}
.column-email .cell{
  padding-left: 5px;
}
.invite-operate-panel{
  display: flex;justify-content:space-between;align-items:center;
  position: absolute;
  bottom: 10px;
  width: 100%;

}
.email-invite-btn{
  margin-right: 10px;
}
.idr-img{
  width:100%;
  flex: 0 1 180px;
  margin-top: 38px;
  background: url(../assets/images/invite_user.png) no-repeat 50% 50%/ auto 143%;
}
.invite-dialog-rightPart .el-select__tags > span{
  overflow-y: auto;
  overflow-x: hidden;
  max-height:62px
}
</style>
