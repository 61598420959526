//java enum 转 js obj
//([A-Z|_]+)\((.+)\) -> $1:$2

export const MessagesInfoType = {
     CREATE_DRAFT:1,//创建了草稿
     INITIATE_APPROVAL:2,//发起审批
     AWAIT_APPROVAL:3,//待审批
     ACCEPT_APPROVAL:4,//审批通过
     REJECT_APPROVAL:5,//审批驳回
     CONTRACT_SIGNED:6,//合同已签署
     ADD_COLLABORATOR:7,//添加协作者
     ADD_COMMENT:8,//添加评论
     UPLOAD_NEW_VERSION:9,//上传新版本
     UPLOAD_ATTACHMENT:10,//上传附件
     JOIN_TEAM:11,//加入团队
     CANCEL_CONTRACT:12,//取消合同
     SET_READY_TO_SIGN:13,//设为定稿
     DELETE_ATTACHMENT:14,//删除附件
     UPLOAD_SIGNED_CONTRACT:15, //上传已签署合同
     AWAIT_PUBLISHED_TEMPLATE_APPROVAL:16,//模板待审批
     ACCEPT_PUBLISHED_TEMPLATE_APPROVAL:17,//模板审批通过
     REJECT_PUBLISHED_TEMPLATE_APPROVAL:18,//模板审批驳回
     DELETE_DOCUMENT:19,//删除文档
     CANCEL_DELETE_DOCUMENT:20,//取消删除文档
     COMPLETELY_DELETE_DOCUMENT:21,//彻底删除文档
     EDIT_CONTRACT:22,//编辑合同
     VIEW_CONTRACT:23,//查看合同
     CREATE_PUBLISHED_TEMPLATE:24, // 发布模板
     REPUBLISH_PUBLISHED_TEMPLATE:25, // 重新发布模板
     SOLVE_COMMENT:26,//解决批注
     DOWNLOAD_ATTACHMENT:27,//下载附件
     JOIN_TEAM_FAIL:28,//加入团队失败
     DIFF_DIFFERENT:34,//上传签署版差异
     ADD_COMMENT_WITHIN_APPROVAL:35,//审批中合同添加评论
     ADD_SUB_COMMENT:36,//添加子评论
     CONTRACT_REVERT:37,//合同回退
     ACCEPT_STAGE_APPROVAL:38,//当前审批通过
     UPLOAD_INVOICE:39,//上传发票
     DELETE_INVOICE:40,//删除发票
     DOWNLOAD_INVOICE:41,//下载发票
     CONTRACT_BULLETIN_CREATED:49,//创建公告
     CONTRACT_BULLETIN_UPDATED:50,//公告更新
     CONTRACT_BULLETIN_DELETED:51,//删除公告
     CONTRACT_TAG_COMPLETED:52,//完成tag的待办事项
     CONTRACT_TAG_COMPLETE_CANCELED:53,//取消tag待办
};

export const SourceType ={
     PUBLISHED_TEMPLATE:1, // 模板
     CONTRACT:2, // 合同
     USER_INFO:3,//用户信息相关
     PRO:4,//模板草稿
     OTHER:10, // 其他 如果功能明确建议另起新类型
};