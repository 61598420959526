<template>
  <div class="inviteUrlWrapper">
    <div class="title"><strong>邀请成员加入</strong> <span class="invite-organization-name">
<!--      {{organization_name}}-->
      团队
    </span>，可通过下列任意方式邀请成员加入</div>
    <div class="inviteUrlContainer">
      <div class="urlInvite cc">
        <div class="invite-way-title">团队链接</div>
        <div class="invite-way-desc f6">成员访问链接可申请加入团队</div>
        <el-scrollbar height="130px" class="inviteUrlDiv inviteContent">
            {{inviteUrl}}
        </el-scrollbar>
        <div class="holder"></div>
        <div class="copyInviteUrlButton">
          <el-button @click="copyInviteUrlButtonClicked">复制邀请链接</el-button>
        </div>
      </div>
      <div class="qrcodeInvite cc">
        <div class="invite-way-title">团队二维码</div>
        <div class="invite-way-desc f6">成员可扫描二维码加入团队</div>
        <div class="inviteQRCodeDiv inviteContent">
          <img id="inviteMemberQRCodeImg" src="">
        </div>
        <div class="holder"></div>
        <div class="copyInviteUrlButton">
          <el-button @click="downloadInviteQRCodeButtonClicked">下载二维码</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ElLoading, ElMessage} from 'element-plus';
import config from "../config";
import {
  downloadOrganizationInviteMemberQRCode,
  getOrganizationInviteMemberQRCode,
  organizationInviteMember
} from "../api/api";
import {MetaMessage} from "../util";
import {globalTagClick} from "../assets/js/tag/tag";

export default {
  name: "organizationInviteUser",
  props: [
    'organizations',

  ],
  data: function () {
    return {
      metaMessage: new MetaMessage(),
      userInfo: JSON.parse(sessionStorage.getItem("userInfo")),
      inviteUrlPrefix: '我和“meta”的小伙伴都在MetaGo等你，用这个专属链接加入我们吧！',
      inviteUrl: '',
      loadingInstance: null,
      organization_name:'',
      organization_id:''
    };
  },
  mounted: function () {
    let names=[];
    let ids=[];
    this.organizations.forEach((item)=>{names.push(item.name);ids.push(item.id)})
    this.organization_name=names.toString();
    this.organization_id=ids.toString();
    console.log('organizationInviteUser.vue  this.organization_name === ', this.organization_name)
    console.log('organizationInviteUser.vue  this.organization_id === ', this.organization_id)
    organizationInviteMember(this.userInfo.mainSiteTeamId,this.organization_id,window.location.href).then(res=>{
      this.inviteUrl = this.inviteUrlPrefix + res.data.data;
    })
  },
  watch:{
    'organizations':function (){
      let names=[];
      let ids=[];
      this.organizations.forEach((item)=>{names.push(item.name);ids.push(item.id)})
      this.organization_name=names.toString();
      this.organization_id=ids.toString();
      organizationInviteMember(this.userInfo.mainSiteTeamId,this.organization_id,window.location.href).then(res=>{
        this.inviteUrl = this.inviteUrlPrefix + res.data.data;
      })

      this.initQRCode();
    }

  },
  methods: {
    initQRCode: function () {
      getOrganizationInviteMemberQRCode(this.userInfo.mainSiteTeamId,this.organization_id).then(res => {
        console.log('获取组织邀请二维码  res:', res);
        document.getElementById("inviteMemberQRCodeImg").setAttribute('src', 'data:image/png;base64,' + res.data.data);
      }).catch(err => {
        console.error('获取组织邀请二维码失败  err:', err);
        this.metaMessage['error']('获取组织邀请二维码失败');
      })
    },
    copyInviteUrlButtonClicked: function () {
      globalTagClick('invite-copy-invite-url');

      let copyTest = this.inviteUrl.substring(this.inviteUrlPrefix.length);
      let inputTest = document.createElement('input');
      inputTest.value = copyTest;
      document.body.appendChild(inputTest);
      inputTest.select();
      document.execCommand('Copy');
      inputTest.className = 'oInput';
      inputTest.style.display = 'none';
      inputTest.remove();
      this.metaMessage['info']('复制成功');
    },
    downloadInviteQRCodeButtonClicked: function () {
      globalTagClick('invite-download-invite-QRCode');
      this.openLoading();
      let img = document.getElementById("inviteMemberQRCodeImg")

      let a = document.createElement('a');
      let filename = decodeURIComponent("邀请二维码");
      a.href = img.src;
      a.download = filename;
      a.click();

      this.closeLoading();


    },
    openLoading: function() {
      this.loadingInstance = ElLoading.service({
        fullscreen: true,
        body: true,
        lock: true,
        spinner: 'el-icon-loading'
      });
    },
    closeLoading: function () {
      this.loadingInstance.close();
      this.loadingInstance = null;
    },
  },
}
</script>

<style scoped>
.title{
}
.holder{
  flex-grow: 1;
}
.invite-organization-name{
}
.inviteQRCodeDiv.inviteContent{
  height: 146px;
  overflow: hidden;
  padding-bottom:5px;
}
.inviteContent {
  margin: 20px 0 0;
  overflow: auto;
  flex-grow: 0;
}
.invite-way-title{
  font-weight: 400;
}
.invite-way-desc{
  color: #a5a5a5;
}
.inviteUrlWrapper {
  border-radius: 4px;
  padding: 20px 20px 0;
}



.cc {
  width: 45%;
  display: flex;
  flex-direction: column;
  background-color:var(--near-white) ;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 3px;
}

.inviteUrlContainer {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  margin-top: 20px;
  flex-grow: 1;
}
.inviteUrlDiv{
  background-color: var(--gray-1);
  box-sizing: border-box;
  padding: 5px 5px 0;
  margin-bottom: 5px;
}
.inviteQRCodeDiv {
}

#inviteMemberQRCodeImg {
  /*和左侧文本高度一致*/
  height: 100%;
  width:auto
}
</style>
